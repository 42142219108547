import { render, staticRenderFns } from "./CreativeBriefing.vue?vue&type=template&id=3971dc80&scoped=true&"
import script from "./CreativeBriefing.vue?vue&type=script&lang=js&"
export * from "./CreativeBriefing.vue?vue&type=script&lang=js&"
import style0 from "./CreativeBriefing.vue?vue&type=style&index=0&id=3971dc80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3971dc80",
  null
  
)

export default component.exports